import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { faIdCard, faCoins, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Membership = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [memberData, setMemberData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastScroll, setLastScroll] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  
  // Create refs for scrolling
  const membershipCardRef = useRef(null);
  const formRef = useRef(null);

  // Similar scroll effect as in Home.js
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll && currentScroll > 100) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScroll(currentScroll);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScroll]);

  // Scroll to membership card when data is loaded
  useEffect(() => {
    if (memberData && membershipCardRef.current) {
      membershipCardRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start' 
      });
    }
  }, [memberData]);

  const handleInputChange = (e) => {
    // Only allow numbers
    const value = e.target.value.replace(/[^0-9]/g, '');
    setPhoneNumber(value);
    // Clear error when user types
    if (error) setError(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchMemberData();
    }
  };

  const fetchMemberData = async () => {
    // Adjust validation to be more lenient or match your backend requirements
    if (!phoneNumber || phoneNumber.length < 8) {
      setError('Please enter a valid phone number');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      console.log('Fetching data for phone number:', phoneNumber);
      // Make API call to the backend endpoint with proper headers
      const response = await axios.post(
        'https://botum-6ea16f5d8e73.herokuapp.com/loyaltyPoints/getMember', 
        { phoneNumber: phoneNumber },
        { 
          headers: { 
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('API response:', response.data);
      
      // Check if a member was found
      if (response.data && response.data.length > 0) {
        const member = response.data[0]; // Get the first member returned
        setMemberData({
          memberId: member.id || 'BT' + phoneNumber.substring(0, 5),
          points: member.Points || 0,
          lastUpdated: member.LastUpdated || new Date().toISOString(),
          phoneNumber: member.phoneNumber
        });
      } else {
        setError('No membership found with this phone number.');
      }
    } catch (err) {
      console.error('Error fetching member data:', err);
      setError('Could not find membership information. Please check your phone number and try again.');
    } finally {
      setLoading(false);
    }
  };

  // Calculate discount value based on points (1 point = $0.01)
  const calculateDiscount = (points) => {
    return (points * 0.01).toFixed(2);
  };

  // Format date from ISO string to local date string
  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Unknown';
    }
  };

  return (
    <div className="font-['Helvetica_Neue',Arial,sans-serif] bg-[#ffffff] text-[#1a1a1a] overflow-x-hidden min-h-screen">

      {/* Membership Section */}
      <section className="pt-20 sm:pt-24 md:pt-32 pb-10 sm:pb-16 px-4 sm:px-6 md:px-8">
        <div className="max-w-[800px] mx-auto">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-light tracking-wider leading-tight uppercase mb-4 sm:mb-6 text-[#8B2332] text-center">
            BOTUM Membership
          </h1>
          <p className="text-base sm:text-lg md:text-xl tracking-wider mb-6 sm:mb-10 font-light text-center">
            Check your membership status and rewards
          </p>

          {/* Phone Number Input Form */}
          <div ref={formRef} className="mb-8 sm:mb-12 bg-[#F5F5F5] p-4 sm:p-6 md:p-8 rounded-lg shadow-sm">
            <div className="mb-4 sm:mb-6">
              <label htmlFor="phoneNumber" className="block text-sm sm:text-base font-light tracking-wider mb-2 sm:mb-3 text-[#8B2332]">
                Enter Your Phone Number
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="e.g., 0812345678"
                className="w-full py-3 sm:py-4 px-4 sm:px-5 border border-[#ddd] focus:border-[#8B2332] focus:ring-1 focus:ring-[#8B2332] outline-none text-base sm:text-lg rounded-md"
                maxLength={10}
                aria-label="Phone number"
              />
              {error && (
                <p className="text-red-500 mt-2 text-sm">{error}</p>
              )}
            </div>
            <button 
              type="button" 
              onClick={fetchMemberData}
              className="w-full py-3 sm:py-4 px-6 sm:px-8 bg-[#8B2332] text-white no-underline uppercase tracking-wider text-sm sm:text-base transition-all duration-300 hover:bg-[#1a1a1a] border-none rounded-md flex items-center justify-center"
              disabled={loading}
            >
              {loading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                  <span>Checking...</span>
                </>
              ) : (
                'Check Membership'
              )}
            </button>
          </div>

          {/* Points Explanation Section - Smaller and more subtle */}
          <div className="mb-8 sm:mb-10 bg-[#F9F6EF] p-4 sm:p-6 rounded-lg border border-[#E5DED1]">
            <p className="text-sm sm:text-base tracking-wider mb-3 sm:mb-4 text-center text-[#555]">
              At BOTUM, your purchases earn you points that you can redeem for future discounts.
            </p>
            <div className="flex justify-center gap-4 sm:gap-6 flex-wrap text-center">
              <div className="px-3 sm:px-4 py-2 sm:py-3">
                <span className="text-xs sm:text-sm text-[#555]">For every $1 spent</span>
                <p className="text-sm sm:text-base text-[#8B2332] font-medium">1 point earned</p>
              </div>
              <div className="px-3 sm:px-4 py-2 sm:py-3">
                <span className="text-xs sm:text-sm text-[#555]">Each point is worth</span>
                <p className="text-sm sm:text-base text-[#8B2332] font-medium">$0.01 in discounts</p>
              </div>
            </div>
          </div>

          {/* Membership Card Display */}
          {memberData && (
            <div 
              ref={membershipCardRef} 
              className="bg-gradient-to-r from-[#8B2332] to-[#C4A662] p-[2px] rounded-xl shadow-lg transition-all duration-500 opacity-100 transform translate-y-0"
            >
              <div className="bg-white p-4 sm:p-6 md:p-8 rounded-xl">
                <div className="text-center mb-6 sm:mb-8">
                  <h2 className="text-xl sm:text-2xl font-light tracking-wider text-[#8B2332]">
                    Welcome Back
                  </h2>
                  <p className="text-sm sm:text-base tracking-wider text-[#1a1a1a] opacity-70 mt-2">
                    Last Purchased: {formatDate(memberData.lastUpdated)}
                  </p>
                </div>

                <div className="grid md:grid-cols-2 gap-4 sm:gap-6 mb-6 sm:mb-8">
                  <div className="text-center p-4 sm:p-6 bg-[#F5F5F5] rounded-lg">
                    <div className="text-xl sm:text-2xl text-[#8B2332] mb-3 sm:mb-4">
                      <FontAwesomeIcon icon={faIdCard} />
                    </div>
                    <h3 className="text-sm sm:text-base font-light tracking-wider mb-2 text-[#8B2332]">Membership ID</h3>
                    <p className="text-base sm:text-lg font-medium">{memberData.memberId}</p>
                  </div>
                  
                  <div className="text-center p-4 sm:p-6 bg-[#F5F5F5] rounded-lg">
                    <div className="text-xl sm:text-2xl text-[#8B2332] mb-3 sm:mb-4">
                      <FontAwesomeIcon icon={faCoins} />
                    </div>
                    <h3 className="text-sm sm:text-base font-light tracking-wider mb-2 text-[#8B2332]">Points Balance</h3>
                    <p className="text-base sm:text-xl font-medium">{memberData.points} points</p>
                    <p className="text-sm sm:text-base text-[#8B2332] mt-2 font-medium">Worth ${calculateDiscount(memberData.points)} in discounts</p>
                  </div>
                </div>

                <div className="text-center">
                  <a 
                    href="https://ig.me/m/botum.official" 
                    className="py-3 px-6 sm:py-4 sm:px-8 bg-[#C4A662] text-[#1a1a1a] no-underline uppercase tracking-wider text-xs sm:text-sm transition-all duration-300 hover:bg-[#8B2332] hover:text-white inline-block rounded-md"
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {/* Footer */}
      <footer className="py-10 sm:py-16 px-4 sm:px-8 bg-[#8B2332] text-white mt-12 sm:mt-20">
        <div className="max-w-[1400px] mx-auto text-center">
          <h4 className="text-sm sm:text-base font-normal tracking-wider mb-3 sm:mb-4 uppercase text-white">BOTUM Membership Program</h4>
          <p className="text-white opacity-70 leading-relaxed text-xs sm:text-sm max-w-[600px] mx-auto">
            Join our membership program to earn points with every purchase. Redeem your points for exclusive discounts and special offers. The more you shop, the more you save.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Membership;