import React, { useState, useRef, useEffect } from 'react';

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  
  const testimonials = [
    {
      id: 1,
      name: "Ry Lita",
      quote: "trust the process mg bong 🥰🥰",
      beforeImg: "/images/mask/testimonials/before5.png",
      afterImg: "/images/mask/testimonials/after5.png",
      rating: 5
    },
    {
      id: 2,
      name: "Chamroeun",
      quote: "ជួយដល់មុនខ្ទុះខ្លាំង",
      beforeImg: "/images/mask/testimonials/before2.png",
      afterImg: "/images/mask/testimonials/after2.png",
      rating: 5
    },
    {
      id: 3,
      name: "Na",
      quote: "B kron jeang mun far 💕",
      beforeImg: "/images/mask/testimonials/before7.png",
      afterImg: "/images/mask/testimonials/after7.png",
      rating: 5
    },
    {
      id: 4,
      name: "Tra KalitTo",
      quote: "មុខញុមគ្រាន់បើជាងមុនហើយបង 🤍",
      beforeImg: "/images/mask/testimonials/before6.jpg",
      afterImg: "/images/mask/testimonials/after6.jpg",
      rating: 5
    },
    {
      id: 5,
      name: "nayok_1234",
      quote: "Terb use os 1set te. nh lerng jg os sngkherm ng mok klun eg hz mk ban use butom lerng mok jea sby jit mg oy feedback tang yub",
      beforeImg: "/images/mask/testimonials/before4.png",
      afterImg: "/images/mask/testimonials/after4.png",
      rating: 5
    },
    {
      id: 6,
      name: "Lii Lii Sa Sa",
      quote: "វាត្រជាក់អត់ធ្ងន់មុខ ទេ. ហើយរន្ធរោមតូចជាងមុន។ មុនប្រើមួយថ្ងៃ រន្ធរោមធំ។ ល្អប្រើមែនតើ. ជាងម៉ាសគេធម្មជាតិ",
      beforeImg: "/images/mask/testimonials/before1.png",
      afterImg: "/images/mask/testimonials/after1.png",
      rating: 5
    },
    {
      id: 7,
      name: "ម៉ៃ",
      quote: "Jrg oy feedback b klas ❤️",
      beforeImg: "/images/mask/testimonials/before3.jpg",
      afterImg: "/images/mask/testimonials/after3.jpg",
      rating: 5
    }
  ];

  // Calculate how many slides to show based on viewport
  const [slidesToShow, setSlidesToShow] = useState(1);
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setSlidesToShow(1); // Mobile: 1 slide
      } else if (window.innerWidth < 1024) {
        setSlidesToShow(2); // Tablet: 2 slides
      } else {
        setSlidesToShow(3); // Desktop: 3 slides
      }
    };
    
    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate total number of pages based on slidesToShow
  const totalPages = Math.ceil(testimonials.length / slidesToShow);

  // Move to previous testimonial group
  const goToPrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? totalPages - 1 : prevIndex - 1;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  // Move to next testimonial group
  const goToNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex === totalPages - 1 ? 0 : prevIndex + 1;
      scrollToIndex(newIndex);
      return newIndex;
    });
  };

  // Go to specific testimonial group
  const goToPage = (index) => {
    setCurrentIndex(index);
    scrollToIndex(index);
  };

  // Helper function to scroll to specified index
  const scrollToIndex = (index) => {
    if (carouselRef.current) {
      const slideWidth = carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: index * slideWidth,
        behavior: 'smooth'
      });
    }
  };
  
  return (
    <section className="py-12 sm:py-16 md:py-24 px-4 sm:px-6 md:px-12 bg-white">
      <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-light tracking-wider mb-8 sm:mb-12 text-[#399649]">
        Real Results from Real People
      </h2>
      
      <div className="relative max-w-[1400px] mx-auto">
        {/* Carousel Controls */}
        <button 
          className="absolute top-1/2 -left-1 sm:-left-4 md:-left-5 transform -translate-y-1/2 bg-[#399649] text-white w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center z-10 shadow-md hover:bg-[#2a7334] transition-colors"
          onClick={goToPrev}
          aria-label="Previous testimonials"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
          </svg>
        </button>
        
        <button 
          className="absolute top-1/2 -right-1 sm:-right-4 md:-right-5 transform -translate-y-1/2 bg-[#399649] text-white w-8 h-8 sm:w-10 sm:h-10 rounded-full flex items-center justify-center z-10 shadow-md hover:bg-[#2a7334] transition-colors"
          onClick={goToNext}
          aria-label="Next testimonials"
          type="button"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 sm:h-5 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
          </svg>
        </button>
        
        {/* Carousel Container */}
        <div className="overflow-hidden">
          <div 
            ref={carouselRef}
            className="flex snap-x snap-mandatory w-full"
            style={{ 
              WebkitOverflowScrolling: 'touch',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              scrollSnapType: 'x mandatory',
              overflowX: 'auto',
              scrollBehavior: 'smooth'
            }}
          >
            {/* Create pages of testimonials */}
            {Array.from({ length: totalPages }).map((_, pageIndex) => (
              <div 
                key={`page-${pageIndex}`}
                className="w-full flex-shrink-0 flex-grow-0 snap-center"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                  {/* Get testimonials for this page */}
                  {testimonials
                    .slice(
                      pageIndex * slidesToShow, 
                      Math.min((pageIndex + 1) * slidesToShow, testimonials.length)
                    )
                    .map((testimonial) => (
                      <div 
                        key={testimonial.id}
                        className="bg-white p-4 sm:p-6 rounded-lg shadow-md flex flex-col h-full border border-gray-100"
                      >
                        {/* Before & After Images */}
                        <div className="mb-4 sm:mb-6">
                          <div className="grid grid-cols-2 gap-2 sm:gap-3">
                            <div className="relative rounded-lg overflow-hidden">
                              <div className="aspect-square">
                                <img 
                                  src={testimonial.beforeImg} 
                                  alt={`${testimonial.name} before using BOTUM`} 
                                  className="w-full h-full object-cover"
                                />
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-[#399649] text-white text-xs py-1 px-2 text-center">
                                Before
                              </div>
                            </div>
                            <div className="relative rounded-lg overflow-hidden">
                              <div className="aspect-square">
                                <img 
                                  src={testimonial.afterImg} 
                                  alt={`${testimonial.name} after using BOTUM`} 
                                  className="w-full h-full object-cover"
                                />
                              </div>
                              <div className="absolute bottom-0 left-0 right-0 bg-[#399649] text-white text-xs py-1 px-2 text-center">
                                After
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        {/* Testimonial Content */}
                        <div className="flex-grow">
                          <p className="text-sm sm:text-base leading-relaxed text-gray-800 mb-3 sm:mb-4 italic h-16 sm:h-20 overflow-hidden">
                            "{testimonial.quote}"
                          </p>
                          <div className="flex text-[#f4d463] mb-2 sm:mb-3">
                            {[...Array(testimonial.rating)].map((_, i) => (
                              <span key={i} className="text-base sm:text-lg">★</span>
                            ))}
                          </div>
                          <p className="text-xs sm:text-sm font-medium text-[#399649]">- {testimonial.name}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Carousel Indicators */}
        <div className="flex justify-center mt-6 sm:mt-8">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button 
              key={index}
              className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full mx-1 sm:mx-2 transition-all duration-300 ${
                currentIndex === index 
                  ? 'bg-[#399649] scale-125' 
                  : 'bg-[#399649] bg-opacity-30 hover:bg-opacity-50'
              }`}
              onClick={() => goToPage(index)}
              aria-label={`Go to testimonial group ${index + 1}`}
              aria-current={currentIndex === index ? 'true' : 'false'}
              type="button"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;