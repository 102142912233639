import React, { useState, useEffect } from 'react';
import { faStar, faFaceSmile, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home = () => {
  const [lastScroll, setLastScroll] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll && currentScroll > 100) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScroll(currentScroll);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScroll]);
  
  const openInstagram = () => {
    window.open('https://www.instagram.com/botum.official/', '_blank');
  };
  
  return (
    <div className="font-['Helvetica_Neue',Arial,sans-serif] bg-[#ffffff] text-[#1a1a1a] overflow-x-hidden">

      {/* Hero Section */}
      <section className="min-h-screen relative bg-white flex items-center justify-center px-4 sm:px-6 md:px-8 py-24 md:py-24">
        <div className="grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 max-w-[1400px] w-full items-center">
          <div className="p-4 sm:p-6 md:p-8 lg:p-10 order-2 md:order-1">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-light tracking-widest leading-tight uppercase mb-4 sm:mb-6 md:mb-8 text-[#8B2332]">
              Terrahertz Gua Sha<br/>By BOTUM
            </h1>
            <p className="text-base sm:text-lg md:text-xl tracking-wider mb-6 sm:mb-8 md:mb-10 font-light">
              Elevate your beauty ritual with our premium terahertz stone facial Gua Sha
            </p>
            <div className="flex">
              <a href="https://ig.me/m/botum.official" className="py-3 sm:py-4 px-6 sm:px-8 md:px-10 lg:px-12 bg-[#8B2332] text-white no-underline uppercase tracking-widest text-sm sm:text-base transition-all duration-300 hover:bg-[#1a1a1a] w-full sm:w-auto text-center">
                BUY NOW
              </a>
            </div>
          </div>
          <div className="order-1 md:order-2 mb-8 md:mb-0">
            <img src="./images/hero.jpeg" alt="Luxurious Gua Sha Tool" className="w-full h-auto shadow-lg rounded-sm" />
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 sm:py-20 md:py-24 px-4 sm:px-8 md:px-12 bg-white">
        <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-light tracking-wider mb-10 sm:mb-16 text-[#1a1a1a]">Benefits of Gua Sha</h2>
        <div className="grid md:grid-cols-2 gap-8 sm:gap-10 md:gap-12 max-w-[1400px] mx-auto">
          <div className="w-full aspect-square md:aspect-auto overflow-hidden">
            <img src="/images/models/benefits.jpeg" alt="Gua Sha Benefits" className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="grid gap-6 sm:gap-8">
              <div className="text-center p-4 sm:p-6">
                <div className="text-xl sm:text-2xl text-[#8B2332] mb-4">
                  <FontAwesomeIcon icon={faStar} />
                </div>
                <h3 className="text-base sm:text-lg font-light tracking-wider mb-3 text-[#8B2332]">Lymphatic Drainage</h3>
                <p className="text-sm sm:text-base leading-relaxed text-[#1a1a1a]">
                  Enhances natural detoxification by stimulating lymphatic flow, reducing facial puffiness and promoting a more sculpted appearance.
                </p>
              </div>
              <div className="text-center p-4 sm:p-6">
                <div className="text-xl sm:text-2xl text-[#8B2332] mb-4">
                  <FontAwesomeIcon icon={faFaceSmile} />
                </div>
                <h3 className="text-base sm:text-lg font-light tracking-wider mb-3 text-[#8B2332]">Tension Relief</h3>
                <p className="text-sm sm:text-base leading-relaxed text-[#1a1a1a]">
                  Releases facial tension, particularly in areas prone to holding stress such as the jaw, temples, and forehead.
                </p>
              </div>
              <div className="text-center p-4 sm:p-6">
                <div className="text-xl sm:text-2xl text-[#8B2332] mb-4">
                  <FontAwesomeIcon icon={faCircleDot} />
                </div>
                <h3 className="text-base sm:text-lg font-light tracking-wider mb-3 text-[#8B2332]">Circulation Boost</h3>
                <p className="text-sm sm:text-base leading-relaxed text-[#1a1a1a]">
                  Improves blood flow to enhance skin's natural radiance and promote a healthy, glowing complexion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Heritage Section */}
      <section className="py-16 sm:py-20 md:py-24 px-4 sm:px-8 md:px-12 bg-white">
        <div className="grid md:grid-cols-2 gap-8 sm:gap-10 md:gap-12 max-w-[1400px] mx-auto">
          <div className="flex flex-col justify-center order-2 md:order-1">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-light tracking-wider mb-4 sm:mb-6 text-[#8B2332]">Ancient Heritage</h2>
            <p className="text-sm sm:text-base md:text-lg leading-relaxed text-[#1a1a1a] mb-6">
              Gua Sha is an ancient healing technique from Traditional Chinese Medicine, dating back thousands of years. This practice of "scraping" with a smooth-edged tool has been used to promote circulation and healing. Today, this time-honored tradition has been refined into a luxurious facial treatment, combining ancestral wisdom with modern beauty rituals.
            </p>
          </div>
          <div className="w-full aspect-square md:aspect-auto overflow-hidden order-1 md:order-2 mb-6 md:mb-0">
            <img src="/images/models/heritage.jpeg" alt="Gua Sha Heritage" className="w-full h-full object-cover" />
          </div>
        </div>
      </section>

      {/* Terahertz Section */}
      <section className="py-16 sm:py-20 md:py-24 px-4 sm:px-8 md:px-12 bg-[#F5F5F5]">
        <div className="grid md:grid-cols-2 gap-8 sm:gap-10 md:gap-12 max-w-[1400px] mx-auto">
          <div className="w-full aspect-square md:aspect-auto overflow-hidden mb-6 md:mb-0">
            <img src="/images/8.jpeg" alt="Terahertz Technology" className="w-full h-full object-cover" />
          </div>
          <div className="flex flex-col justify-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-light tracking-wider mb-4 sm:mb-6 text-[#8B2332]">Terahertz Innovation</h2>
            <p className="text-sm sm:text-base md:text-lg leading-relaxed text-[#1a1a1a] mb-6">
              Our Gua Sha tool harnesses the power of terahertz technology, a groundbreaking advancement in skincare. Terahertz waves penetrate deeply into the skin, enhancing the tool's effectiveness. This fusion of ancient wisdom and cutting-edge science creates a uniquely powerful skincare experience, setting new standards in luxury beauty.
            </p>
          </div>
        </div>
      </section>

      {/* Collection Section */}
      <section className="py-16 sm:py-20 md:py-24 px-4 sm:px-8 md:px-12 bg-[#F5F5F5]">
        <h2 className="text-center text-2xl sm:text-3xl md:text-4xl font-light tracking-wider mb-10 sm:mb-16 text-[#1a1a1a]">THE COLLECTION</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8 max-w-[1400px] mx-auto">
          <div className="relative aspect-square overflow-hidden bg-white">
            <img src="/images/detail.jpeg" alt="Gua Sha Detail" className="w-full h-full object-cover transition-transform duration-300 hover:scale-105" />
          </div>
          <div className="relative aspect-square overflow-hidden bg-white">
            <img src="./images/models/application.jpeg" alt="Application" className="w-full h-full object-cover transition-transform duration-300 hover:scale-105" />
          </div>
          <div className="relative aspect-square overflow-hidden bg-white sm:col-span-2 md:col-span-1">
            <img src="./images/premium-packaging.jpeg" alt="Premium Packaging" className="w-full h-full object-cover transition-transform duration-300 hover:scale-105" />
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-16 sm:py-20 md:py-24 px-4 sm:px-8 text-center bg-[#8B2332] text-white">
        <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-wider mb-6 sm:mb-8 text-white">JOIN THE BOTUM FAMILY</h3>
        <button 
          onClick={openInstagram} 
          className="py-3 sm:py-4 px-6 sm:px-8 md:px-10 bg-[#C4A662] text-[#1a1a1a] border-none uppercase tracking-wider text-sm sm:text-base cursor-pointer transition-all duration-300 hover:bg-white"
        >
          CHECK US OUT
        </button>
      </section>

      {/* Footer */}
      <footer className="py-12 sm:py-16 md:py-20 px-4 sm:px-8 md:px-12 bg-[#8B2332] text-white">
        <div className="max-w-[1400px] mx-auto grid md:grid-cols-2 gap-8 sm:gap-10 md:gap-12">
          <div className="footer-column">
            <h4 className="text-sm sm:text-base font-normal tracking-wider mb-4 sm:mb-6 uppercase text-white">About Botum</h4>
            <p className="text-white opacity-70 leading-relaxed text-sm sm:text-base">
              Founded in Thailand in 2021, BOTUM is a luxury skincare brand committed to creating all-natural, highly effective products. Our current collection features the BOTUM Mask & Spray for comprehensive acne care and our innovative terahertz gua sha tool. Stay tuned for our upcoming releases including the Botum Non-Sticky Body Sunscreen, Under Eye Stone, and Mini Rollers.
            </p>
          </div>
          <div className="footer-column">
            <h4 className="text-sm sm:text-base font-normal tracking-wider mb-4 sm:mb-6 uppercase text-white">Follow Us</h4>
            <ul className="list-none">
              <li className="mb-3 sm:mb-4">
                <a href="https://www.instagram.com/botum.official/" target="_blank" rel="noopener noreferrer" className="text-white opacity-70 no-underline transition-all duration-300 hover:text-white flex items-center">
                  <FontAwesomeIcon icon={faInstagram} className="mr-2 text-base sm:text-lg" /> 
                  <span>Instagram</span>
                </a>
              </li>
              <li className="mb-3 sm:mb-4">
                <a href="https://www.facebook.com/botumofficial" target="_blank" rel="noopener noreferrer" className="text-white opacity-70 no-underline transition-all duration-300 hover:text-white flex items-center">
                  <FontAwesomeIcon icon={faFacebook} className="mr-2 text-base sm:text-lg" /> 
                  <span>Facebook</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* Removed dangerouslySetInnerHTML script since it's redundant with React hooks */}
    </div>
  );
};

export default Home;