import logo from '../logo.svg';

function About() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>About Page</h1>
        <p>
          This is a simple React application using React Router.
        </p>
      </header>
    </div>
  );
}

export default About; 