import React, { useState, useEffect } from 'react';
import { ShoppingBag, Star, Smile, Circle, Instagram, Facebook, Droplet, Shield, Zap } from 'lucide-react';
import TestimonialsSection from '../components/TestimonialSection';

const Mask = () => {
  const [lastScroll, setLastScroll] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > lastScroll && currentScroll > 100) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScroll(currentScroll);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScroll]);
  
  const openInstagram = () => {
    window.open('https://www.instagram.com/botum.official/', '_blank');
  };
  
  return (
    <div className="font-['Helvetica_Neue',Arial,sans-serif] bg-white text-[#1a1a1a] overflow-x-hidden">

      {/* Hero Section */}
      <section className="min-h-screen relative bg-gradient-to-b from-[#399649] to-[#4cb05f] flex items-center justify-center px-4 sm:px-[20px] pt-24 sm:pt-32 md:pt-0 py-16 md:py-0">
        <div className="grid md:grid-cols-2 gap-6 sm:gap-8 md:gap-[40px] max-w-[1400px] w-full mt-[60px] md:mt-[80px] items-center">
          <div className="p-6 sm:p-8 md:p-[40px] order-2 md:order-1">
            <h1 className="text-[clamp(2rem,5vw,4rem)] font-light tracking-[0.15em] sm:tracking-[0.2em] leading-[1.2] uppercase mb-4 sm:mb-6 md:mb-[30px] text-white text-center md:text-left">
              BOTUM Mask<br/>& Spray Set
            </h1>
            <p className="text-base sm:text-[1.1rem] md:text-[1.2rem] tracking-[1px] sm:tracking-[2px] mb-6 sm:mb-8 md:mb-[40px] font-light text-white opacity-90 text-center md:text-left">
              Pure, natural skincare that effectively treats acne, scars, rashes, and redness. A comprehensive solution for all skin types and concerns.
            </p>
            <div className="flex justify-center md:justify-start">
              <a href="https://ig.me/m/botum.official" className="py-3 sm:py-4 md:py-[18px] px-8 sm:px-12 md:px-[100px] bg-[#f4d463] text-[#2a7334] no-underline uppercase tracking-[2px] sm:tracking-[3px] text-[0.9rem] transition-all duration-300 hover:bg-white border-none text-center">
                SHOP NOW
              </a>
            </div>
          </div>
          <div className="flex justify-center items-center bg-white bg-opacity-10 rounded-lg order-1 md:order-2 mb-6 md:mb-0">
            <img src="/images/mask/hero.jpg" alt="BOTUM Mask and Spray Products" className="w-full h-auto drop-shadow-2xl" />
          </div>
        </div>
      </section>

      {/* Products Section */}
      <section className="py-12 sm:py-16 md:py-[120px] px-4 sm:px-8 md:px-[60px] bg-white">
        <h2 className="text-center text-2xl sm:text-3xl md:text-[2.5rem] font-light tracking-wider md:tracking-[5px] mb-8 sm:mb-10 md:mb-[60px] text-[#399649]">Our Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-10 md:gap-[60px] max-w-[1400px] mx-auto">
          <div className="bg-[#f9f9f9] p-6 sm:p-8 md:p-[40px] rounded-lg shadow-md flex flex-col items-center">
            <img src="/images/mask/mask-solo.jpeg" alt="BOTUM Mask" className="w-full max-w-[250px] sm:max-w-[300px] h-auto mb-5 sm:mb-6 md:mb-[30px]" />
            <h3 className="text-xl sm:text-2xl md:text-[1.8rem] font-light tracking-wider md:tracking-[2px] mb-3 sm:mb-4 md:mb-[15px] text-[#399649]">BOTUM Mask</h3>
            <p className="text-base sm:text-lg md:text-[1.1rem] leading-[1.6] text-[#1a1a1a] mb-4 sm:mb-5 md:mb-[20px] text-center">
              Our natural clay mask deeply cleanses and purifies, removing excess oil and impurities while nourishing your skin. Specifically formulated to fight acne, fade scars, calm redness, and heal persistent rashes. For optimal results, mix with our BOTUM Spray before application.
            </p>
            <a href="https://ig.me/m/botum.official" className="py-3 sm:py-4 md:py-[15px] px-6 sm:px-8 md:px-[50px] bg-[#399649] text-white no-underline uppercase tracking-wider md:tracking-[3px] text-sm sm:text-base md:text-[0.9rem] transition-all duration-300 hover:bg-[#2a7334] mt-4 sm:mt-5 md:mt-[20px]">
              View Details
            </a>
          </div>
          
          <div className="bg-[#f9f9f9] p-6 sm:p-8 md:p-[40px] rounded-lg shadow-md flex flex-col items-center">
            <img src="/images/mask/spray-solo.jpeg" alt="BOTUM Spray" className="w-full max-w-[250px] sm:max-w-[300px] h-auto mb-5 sm:mb-6 md:mb-[30px]" />
            <h3 className="text-xl sm:text-2xl md:text-[1.8rem] font-light tracking-wider md:tracking-[2px] mb-3 sm:mb-4 md:mb-[15px] text-[#399649]">BOTUM Spray</h3>
            <p className="text-base sm:text-lg md:text-[1.1rem] leading-[1.6] text-[#1a1a1a] mb-4 sm:mb-5 md:mb-[20px] text-center">
              Our refreshing toning spray soothes inflammation, reduces redness, and balances pH levels when used alone. It helps minimize scarring and calm irritated skin conditions. When mixed with our BOTUM Mask, it creates a powerful treatment for acne, rashes, and other skin concerns.
            </p>
            <a href="https://ig.me/m/botum.official" className="py-[15px] px-[50px] bg-[#399649] text-white no-underline uppercase tracking-[3px] text-[0.9rem] transition-all duration-300 hover:bg-[#2a7334] mt-[20px]">
              View Details
            </a>
          </div>
        </div>
          
      </section>

      {/* Benefits Section */}
      <section className="py-[120px] px-[60px] bg-[#f4f9f5]">
        <h2 className="text-center text-[2.5rem] font-light tracking-[5px] mb-[60px] text-[#399649]">Benefits</h2>
        <div className="grid md:grid-cols-2 gap-[60px] max-w-[1400px] mx-auto">
          <img src="/images/mask/benefits.jpeg" alt="BOTUM Benefits" className="w-full h-auto object-cover rounded-lg shadow-lg" />
          <div className="flex flex-col justify-center">
            <div className="grid gap-[30px]">
              <div className="p-[20px] bg-white rounded-lg shadow-sm">
                <div className="flex items-center mb-[15px]">
                  <div className="w-[50px] h-[50px] rounded-full bg-[#399649] flex items-center justify-center mr-[15px]">
                    <Shield className="text-white h-6 w-6" />
                  </div>
                  <h3 className="text-[1.2rem] font-light tracking-[2px] text-[#399649]">All Natural Ingredients</h3>
                </div>
                <p className="text-[1rem] leading-[1.6] text-[#1a1a1a]">
                  Made with organic ingredients that are gentle on your skin while effectively treating acne, scars, rashes, and persistent redness without harsh chemicals.
                </p>
              </div>
              
              <div className="p-[20px] bg-white rounded-lg shadow-sm">
                <div className="flex items-center mb-[15px]">
                  <div className="w-[50px] h-[50px] rounded-full bg-[#399649] flex items-center justify-center mr-[15px]">
                    <Droplet className="text-white h-6 w-6" />
                  </div>
                  <h3 className="text-[1.2rem] font-light tracking-[2px] text-[#399649]">Hydrating Formula</h3>
                </div>
                <p className="text-[1rem] leading-[1.6] text-[#1a1a1a]">
                  Maintains skin moisture balance while treating blemishes and inflammation, preventing the dryness common with other acne and rash treatments.
                </p>
              </div>
              
              <div className="p-[20px] bg-white rounded-lg shadow-sm">
                <div className="flex items-center mb-[15px]">
                  <div className="w-[50px] h-[50px] rounded-full bg-[#399649] flex items-center justify-center mr-[15px]">
                    <Zap className="text-white h-6 w-6" />
                  </div>
                  <h3 className="text-[1.2rem] font-light tracking-[2px] text-[#399649]">Quick Results</h3>
                </div>
                <p className="text-[1rem] leading-[1.6] text-[#1a1a1a]">
                  Many users report visible improvements in skin clarity, reduced redness, and diminished scarring within just days of starting their BOTUM routine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TestimonialsSection/>

      {/* How To Use Section - MOVED DOWN */}
      <section className="py-[120px] px-[60px] bg-[#f4f9f5]">
        <h2 className="text-center text-[2.5rem] font-light tracking-[5px] mb-[60px] text-[#399649]">How To Use</h2>
        <div className="grid md:grid-cols-2 gap-[60px] max-w-[1400px] mx-auto">
          <div className="flex flex-col justify-center">
            <div className="mb-[40px]">
              <h3 className="text-[1.8rem] font-light tracking-[2px] mb-[20px] text-[#399649]">Combined Treatment Method</h3>
              <ol className="list-decimal pl-[20px] space-y-[15px]">
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Begin with double cleansing or washing your face with your regular foam cleanser.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Mix the BOTUM Mask and Spray together to create a smooth paste.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Apply the mixture evenly to your face or any areas with acne, scars, rashes, or redness, avoiding the eye area.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Leave on for 10-15 minutes until completely dry.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Rinse thoroughly with warm water and pat dry.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Continue with your regular skincare routine as normal.</li>
              </ol>
            </div>
            
            <div>
              <h3 className="text-[1.8rem] font-light tracking-[2px] mb-[20px] text-[#399649]">Usage Frequency</h3>
              <ul className="list-disc pl-[20px] space-y-[15px]">
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Can be used daily or 3-4 times per week, depending on your skin's needs and severity of skin concerns.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Best used in the evening after cleansing.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">For persistent acne, scars, or rashes, consistent application is recommended for best results.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">Recommended routine: Double cleanse → BOTUM treatment → Foam cleanser → Regular skincare products.</li>
                <li className="text-[1.1rem] leading-[1.6] text-[#1a1a1a]">You can customize your routine based on what works best for your specific skin concerns.</li>
              </ul>
            </div>
          </div>
          <img src="/images/mask/how-to-use-horizontal.jpeg" alt="BOTUM Application" className="w-full h-auto object-cover rounded-lg shadow-lg" />
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-[120px] px-[20px] text-center bg-[#399649] text-white">
        <h3 className="text-[2.5rem] font-light tracking-[5px] mb-[30px] text-white">JOIN THE BOTUM FAMILY</h3>
        <button 
          onClick={openInstagram} 
          className="py-[18px] px-[50px] bg-[#f4d463] text-[#399649] border-none uppercase tracking-[3px] cursor-pointer transition-all duration-300 hover:bg-white"
        >
          CHECK US OUT
        </button>
      </section>

      {/* Footer */}
      <footer className="py-[80px] px-[60px] bg-[#2a7334] text-white">
        <div className="max-w-[1400px] mx-auto grid md:grid-cols-2 gap-[60px]">
          <div className="footer-column">
            <h4 className="text-[1rem] font-normal tracking-[3px] mb-[30px] uppercase text-white">
              About Botum
            </h4>
            <p className="text-white opacity-70 leading-[1.6]">
              Founded in Thailand in 2021, BOTUM is a luxury skincare brand committed to creating all-natural, highly effective products. Our current collection features the BOTUM Mask & Spray for comprehensive treatment of acne, scars, rashes, redness, and other skin concerns, along with our innovative terahertz gua sha tool. Stay tuned for our upcoming releases including the Botum Non-Sticky Body Sunscreen, Under Eye Stone, and Mini Rollers.
            </p>
          </div>
          
          <div className="footer-column">
            <h4 className="text-[1rem] font-normal tracking-[3px] mb-[30px] uppercase text-white">
              Follow Us
            </h4>
            <ul className="list-none">
              <li className="mb-[15px]">
                <a 
                  href="https://www.instagram.com/botum.official/" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-white opacity-70 no-underline transition-all duration-300 hover:text-white hover:opacity-100 flex items-center"
                >
                  <Instagram className="w-5 h-5 mr-[10px]" /> Instagram
                </a>
              </li>
              <li className="mb-[15px]">
                <a 
                  href="https://www.facebook.com/botumofficial" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-white opacity-70 no-underline transition-all duration-300 hover:text-white hover:opacity-100 flex items-center"
                >
                  <Facebook className="w-5 h-5 mr-[10px]" /> Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center mt-[60px] text-white opacity-70 text-[0.9rem]">
          © {new Date().getFullYear()} BOTUM. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Mask;