import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // Don't hide navbar when mobile menu is open
      if (mobileMenuOpen) {
        setShowNavbar(true);
        return;
      }
      
      // Set a minimum scroll threshold to avoid flicker
      if (Math.abs(currentScrollY - lastScrollY) < 10) return;
      
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY, mobileMenuOpen]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    // Always show navbar when toggling menu
    setShowNavbar(true);
  };

  // Close mobile menu when window is resized to desktop width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && mobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mobileMenuOpen]);

  return (
    <nav className={`fixed top-0 left-0 right-0 bg-white bg-opacity-98 backdrop-blur-md py-4 px-4 md:py-[25px] md:px-[60px] flex justify-between items-center z-[1000] border-b border-opacity-10 transition-transform duration-300 ${showNavbar ? 'transform-none' : '-translate-y-full'}`}>
      <Link to="/" className="text-xl md:text-[2rem] font-light tracking-[5px] text-[#8B2332] no-underline">BOTUM</Link>
      
      {/* Hamburger Menu Button - Visible on mobile only */}
      <button 
        className="md:hidden flex flex-col justify-center items-center w-8 h-8 focus:outline-none"
        onClick={toggleMobileMenu}
        aria-label="Toggle menu"
      >
        <span className={`bg-gray-800 block h-0.5 w-6 rounded transition-all duration-300 ${mobileMenuOpen ? 'transform rotate-45 translate-y-1.5' : 'mb-1.5'}`}></span>
        <span className={`bg-gray-800 block h-0.5 w-6 rounded transition-all duration-300 ${mobileMenuOpen ? 'opacity-0' : 'mb-1.5'}`}></span>
        <span className={`bg-gray-800 block h-0.5 w-6 rounded transition-all duration-300 ${mobileMenuOpen ? 'transform -rotate-45 -translate-y-1.5' : ''}`}></span>
      </button>
      
      {/* Desktop Menu - Hidden on mobile */}
      <div className="hidden md:flex gap-6">
        <Link to="/" className="text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline">Gua Sha</Link>
        <Link to="/mask" className="text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline">Mask</Link>
        <Link to="/membership" className="text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline">Membership</Link>
      </div>
      
      {/* Mobile Menu - Always visible when open, regardless of scroll */}
      <div className={`md:hidden absolute left-0 right-0 top-[57px] bg-white shadow-md transition-all duration-300 ease-in-out overflow-hidden ${mobileMenuOpen ? 'max-h-screen opacity-100 z-[1001]' : 'max-h-0 opacity-0'}`}>
        <div className="flex flex-col py-2 px-4">
          <Link 
            to="/" 
            className="py-3 text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline border-b border-gray-100"
            onClick={() => setMobileMenuOpen(false)}
          >
            Gua Sha
          </Link>
          <Link 
            to="/mask" 
            className="py-3 text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline border-b border-gray-100"
            onClick={() => setMobileMenuOpen(false)}
          >
            Mask
          </Link>
          <Link 
            to="/membership" 
            className="py-3 text-lg text-gray-800 hover:text-[#8B2332] transition-colors no-underline"
            onClick={() => setMobileMenuOpen(false)}
          >
            Membership
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;